class LS {
    constructor() {
        // TODO
        this.prefix = 'prefix';

        this.get = this.getItem.bind(this);
        this.set = this.setItem.bind(this);
        this.remove = this.removeItem.bind(this);
    }

    doPrefix = (key) => `${this.prefix}.${key}`;

    getItem = (key) => {
        const stringified = localStorage.getItem(this.doPrefix(key));
        if (!stringified || stringified === 'undefined') return undefined;
        let parsed;
        try {
            parsed = JSON.parse(stringified);
        } catch (e) {
            console.warn(`Error while parsing local storage item at "${key}"`)
        }
        return parsed;
    };

    setItem = (key, value) => {
        const stringified = JSON.stringify(value);
        return localStorage.setItem(this.doPrefix(key), stringified);
    };

    removeItem = (key) => {
        return localStorage.removeItem(this.doPrefix(key));
    };
}

const locStorage = new LS();
export default locStorage;