import React from 'react';
import './App.less';
import store from '../../store/store';
import { Provider } from 'react-redux';
import Root from '../Root/Root';
import { App as AntdApp } from 'antd';
import withThemeContext, { ThemeContextProvider } from '../common/ThemeContext/ThemeContext';

const App = () => {
    return (
        <Provider store={store}>
            <ThemeContextProvider>
                <AntdApp>
                    <Root />
                </AntdApp>
            </ThemeContextProvider>
        </Provider>
    );
};

export default withThemeContext(App);