import { applyMiddleware, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';
import reducer from './ducks';
import createSagaMiddleware from 'redux-saga';
import { showError } from './ducks/ui/error';
import sagaRoot from './saga.root';
import { routinePromiseWatcherSaga } from 'redux-saga-routines';
import { middleware as thunkMiddleware } from 'redux-saga-thunk';
import locStorage from '../services/locStorage';

const sagaMiddleware = createSagaMiddleware({
    onError: (error, sagaStack) => {
        console.error(error);
        console.error(sagaStack);
        store.dispatch(showError(error));
    }
});

const middleware = [
    thunkMiddleware,
    sagaMiddleware
];

const logger = createLogger({
    predicate(getState, action) {
        return action.type.indexOf('FULFILLED') !== -1;
    },
    // collapsed: (getState, action) => action.type.indexOf('SAVE_EVENT_LOG') !== -1
    collapsed: true
});

locStorage.set('redux_logger', true);
if (locStorage.get('redux_logger')) middleware.push(logger);
// if (process.env.NODE_ENV !== 'production') middleware.push(logger);

const composeEnhancers = composeWithDevTools({
    trace: process.env.REACT_APP_ENV === 'local',
});

const store = createStore(
    reducer,
    composeEnhancers(
        applyMiddleware(...middleware),
        // other store enhancers if any
    )
);

sagaMiddleware.run(sagaRoot);
sagaMiddleware.run(routinePromiseWatcherSaga);

export default store;