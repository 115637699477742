export default function progressReducer(state = {}, action) {
    const { type, payload } = action;
    const matchesProgress = /(.*)\/(PROGRESS)/.exec(type);
    const matchesCancel = /CANCEL_BG_TASK_STATUS\/TRIGGER/.exec(type);

    if (!matchesProgress && !matchesCancel) return state;

    if (matchesCancel) {
        const newState = { ...state };
        delete newState[payload];
        return newState;
    }

    const { percent, uuid } = payload;

    return {
        ...state,
        // payload is a number 0 - 100
        [uuid]: percent,
    };

};

// get all routines that have a progress
export const getProgress = () => (state) => state.ui.progress;
export const getProgressKeys = () => (state) => Object.keys(state.ui.progress);
export const getProgressById = (uuid) => (state) => state.ui.progress[uuid];