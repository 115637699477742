import { all, takeLeading } from 'redux-saga/effects';
import BaseModel from '../../../services/BaseModel';
import { createFetchRecordSaga, createReducer, RoutineCreator } from '../../store.helpers';

const url = '/forms';
const path = 'forms';

/* ######### Model ######### */

export class Form extends BaseModel {

    static url = url;
    static path = path;
    static refId = 'formId';
    static instanceName = 'form';

}

/* ######### Action types & creators ######### */

const createRoutine = new RoutineCreator({ model: Form });

export const fetchFormByUniqueId = createRoutine('FETCH_FORM_BY_UNIQUE_ID');

/* ######### Reducer ######### */

const reducer = createReducer({
    model: Form,
    types: {
        fetchRecord: fetchFormByUniqueId
    }
});

export default reducer;

/* ######### Selectors ######### */

/* ######### Side-effects ######### */

export function* formsSaga() {
    yield all([
        takeLeading(fetchFormByUniqueId, createFetchRecordSaga({
            url: `${url}/uid`,
            routine: fetchFormByUniqueId
        })),
    ]);
}