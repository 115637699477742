import React from 'react';
import PropTypes from 'prop-types';
import { pick } from 'lodash';

export const DisplayFormState = ({ form = {}, attrs = [] }) => {

    let nodes = form;
    if (attrs.length) nodes = pick(form, attrs);
    const tree = JSON.stringify(nodes, null, 2);

    return (
        <div style={{ margin: '1rem 0' }}>
            <pre style={{ background: '#f6f8fa', fontSize: '.65rem', padding: '.5rem', }}>
                <strong>props</strong> ={' '}
                {tree}
            </pre>
        </div>
    );
};

DisplayFormState.propTypes = {
    form: PropTypes.object,
    attrs: PropTypes.array,
};
