import { all, takeLeading } from 'redux-saga/effects';
import BaseModel from '../../../services/BaseModel';
import { callApi, createReducer, createSaveRecordSaga, RoutineCreator } from '../../store.helpers';
import * as Yup from 'yup';
import countryStates from '../../../services/states';
import { put } from '@redux-saga/core/effects';
import { range } from 'lodash';

const url = '/leads';
const path = 'leads';

/* ######### Model ######### */

export class Lead extends BaseModel {

    static url = url;
    static path = path;
    static refId = 'leadId';
    static instanceName = 'lead';

    static minYear = 1900;
    static maxYear = (new Date()).getFullYear();
    static yearsEnum = range(Lead.minYear, Lead.maxYear + 1).reverse()

    static schema = {
        zip: Yup.string()
            .matches(/^(\d{5}(-\d{4})?|[A-Z]\d[A-Z] ?\d[A-Z]\d)$/i, {
                excludeEmptyString: true,
                message: 'ZIP code does not follow a valid US or Canadian shape'
            })
            .label('Zip Code'),

        state: Yup.string().label('State')
            .when('countryCode', {
                is: 'US',
                then: Yup.string().oneOf(Object.keys(countryStates.US)).required().label('State'),
            })
            .when('countryCode', {
                is: 'CA',
                then: Yup.string().oneOf(Object.keys(countryStates.CA)).required().label('State'),
            }),

        create: Yup.object().shape({
            countryCode: Yup.string().oneOf(['US', 'CA']).strip(),
            firstName: Yup.string().required().label('First Name'),
            lastName: Yup.string().required().label('Last Name'),
            email: Yup.string().email().required().label('Email'),
            phone: Yup.string().required().min(10, 'Phone must be at least 10 digits long').label('Phone'),
            modelYearMileage: Yup.string().required().label('Car Model, Year, Mileage'),
            customFields: Yup.object(),
            mobileOptIn: Yup.boolean().default(false),

            // year: Yup.number().integer().required().min(Lead.minYear).max(Lead.maxYear).label('Year'),
            // makeAndModel: Yup.string().required().label('Make and Model'),
            // mileage: Yup.number().integer().required().min(0).max(3 * 1000 * 1000).label('Mileage'),
        })
    };

}

/* ######### Action types & creators ######### */

const createRoutine = new RoutineCreator({ model: Lead });

export const saveLead = createRoutine('SAVE_LEAD');
export const getZipCodeGeolocation = createRoutine('GET_LEAD_ZIP_CODE_GEOLOCATION');

/* ######### Reducer ######### */

const reducer = createReducer({
    model: Lead,
    types: {
        saveRecord: saveLead,
    }
});

export default reducer;

/* ######### Selectors ######### */

/* ######### Side-effects ######### */

function* getZipCodeGeolocationSaga({ payload, meta }) {
    yield put(getZipCodeGeolocation.request(payload));

    try {
        return yield callApi(
            { url: `${url}/zip-code-geolocation`, method: 'get', params: { zip: payload } },
            getZipCodeGeolocation,
            meta
        );
    } catch(error){
        console.error(error);
        return yield null;
    }
}

export function* leadsSaga() {
    yield all([
        takeLeading(saveLead, createSaveRecordSaga({ url: `${url}/remote`, routine: saveLead })),
        takeLeading(getZipCodeGeolocation, getZipCodeGeolocationSaga),
    ]);
}