import React from 'react';
import PropTypes from 'prop-types';
import { Form as AntdForm } from 'antd';
import { Form as FormikForm } from 'formik';
import SMInput from './SMInput';
import { DisplayFormState } from './DisplayFormState';
import FormikControls from './FormikControls';

const DummyForm = (props) => {
    const { children, ...rest } = props;
    return (<div {...rest} >{children}</div>);
};
DummyForm.propTypes = {
    children: PropTypes.node
};

const SMForm = props => {
    const { children, scrollToFirstError, labelCol, wrapperCol, size, layout, autoComplete, ...rest } = props;
    const antdFormProps = { labelCol, wrapperCol, size, layout, autoComplete };

    return (
        <FormikForm {...rest}>
            <AntdForm component={DummyForm} scrollToFirstError {...antdFormProps}>
                {children}
            </AntdForm>
        </FormikForm>
    );
};

SMForm.propTypes = {
    children: PropTypes.any,
    scrollToFirstError: PropTypes.bool,
    labelCol: PropTypes.object,
    wrapperCol: PropTypes.object,
    layout: PropTypes.string,
    size: PropTypes.oneOf(['small', 'middle', 'large']),
    autoComplete: PropTypes.any,
};


SMForm.SMInput = SMInput;
SMForm.DisplayFormState = DisplayFormState;
SMForm.ErrorFocus = FormikControls.ErrorFocus;

export default SMForm;
