import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import { identity } from 'lodash';
import locStorage from '../../../services/locStorage';

const defaultTheme = process.env.REACT_APP_DEFAULT_THEME;
const themesEnabled = process.env.REACT_APP_THEMES_ENABLED;

export const ThemeContext = createContext({
    theme: defaultTheme,
    isLight: true,
    isDark: false,
    setTheme: identity
});

const validThemes = ['light', 'dark'];

const isValidTheme = (theme) => validThemes.indexOf(theme) !== -1;

const mediaQueryList = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)');
const systemScheme = mediaQueryList?.matches ? 'dark' : 'light';

export function ThemeContextProvider({ children }) {
    // const shopTheme = user?.DefaultShop?.config?.ui?.theme

    const defaultTheme = React.useMemo(() => {
        const stored = locStorage.get('theme');
        const defaultFromEnv = process.env.REACT_APP_DEFAULT_THEME;
        const defaultStrict = 'light';
        if (!themesEnabled) return defaultStrict;
        const theme = stored || systemScheme || defaultFromEnv || defaultStrict;
        return isValidTheme(theme) ? theme : defaultStrict;
    }, []);

    const [theme, setTheme] = React.useState(defaultTheme);
    console.log('ThemeContext theme', theme);

    const handleSetTheme = React.useCallback((targetTheme) => {
        if (!isValidTheme(targetTheme)) return;
        setTheme(targetTheme);
        locStorage.set('theme', targetTheme);
    }, []);

    React.useEffect(() => {
        const handler = (e) => {
            const newSystemTheme = e.matches ? "dark" : "light";
            handleSetTheme(newSystemTheme);
        };
        mediaQueryList.addEventListener('change', handler);
        return () => mediaQueryList.removeEventListener('change', handler);
    }, [handleSetTheme]);


    React.useEffect(() => {
        document.body.classList.remove(theme === 'light' ? `theme-dark` : 'theme-light');
        document.body.classList.add(theme === 'light' ? `theme-light` : 'theme-dark');
    }, [theme]);

    const isDark = React.useMemo(() => theme === 'dark', [theme]);
    const isLight = React.useMemo(() => !isDark, [isDark]);

    return (
        <ThemeContext.Provider value={{ theme, isLight, isDark, setTheme: handleSetTheme }}>
            {children}
        </ThemeContext.Provider>
    );
}

ThemeContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

const withThemeContext = (WrappedComponent) => (props) => (
    <ThemeContextProvider>
        <WrappedComponent {...props} />
    </ThemeContextProvider>
);

export default withThemeContext;