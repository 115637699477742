import { immerable } from 'immer';
import { cloneDeep, isArray, mergeWith } from 'lodash';

export default class BaseModel {

    [immerable] = true;

    static identifier = 'id';

    constructor(props = {}) {

        const { defaultProps } = this.constructor;
        if (defaultProps) Object.assign(this, cloneDeep(defaultProps));

        return this.merge(props);
    }

    mergeCustomizer(objValue, srcValue) {
        if (isArray(srcValue)) {
            return srcValue;
        }
    }

    merge(props = {}, log = false) {
        if (log) {
            console.log('merging props', props);
            console.log('into', JSON.parse(JSON.stringify(this)));
        }
        mergeWith(this, props, this.mergeCustomizer);
        if (log) console.log('this after merge', this);
        return this;
    }

    get isNew() {
        return !this[this.identifier];
    }

    get isStored() {
        return this[this.identifier] > 0;
    }

    get identifier() {
        return 'id';
    }
}