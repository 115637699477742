import React from 'react';
import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';
import { Form, Input } from 'antd';
import { getValidationMessage, getValidationStatus } from '../../../services/utility.service';

const SMInput = (props) => {
    const {
        // Form.Item props
        label,
        required,
        hasFeedback,
        className,
        help,
        wrapperCol,
        labelCol,
        formItemLayout,
        colon,
        style,
        noStyle,

        // Child input props
        autoFocus,
        autoSelect,
        id,
        name,
        input: InputComponent,

        children,
        ...rest
    } = props;

    const formItemProps = { label, hasFeedback, required, className, colon, style, wrapperCol, labelCol, noStyle };

    const ref = React.useRef();

    const form = useFormikContext();
    const [field, , helpers] = useField(props);

    const InputControl = React.useMemo(() => InputComponent || Input, [InputComponent]);

    const getChild = React.useCallback(
        () => {
            if (children) return children({ ...rest, form, field, helpers, ref });
            return <InputControl {...field} {...rest} ref={ref} />;
        },
        [children, field, form, helpers, rest],
    );

    React.useEffect(() => {
        // console.log('autoFocus', autoFocus, ref);
        setTimeout(() => {
            // console.log('should focus:', autoFocus && ref && ref.current && ref.current.focus);
            autoFocus && ref && ref.current && ref.current.focus && ref.current.focus();
            autoSelect && ref && ref.current && ref.current.select && ref.current.select();
        }, 100);
    }, [autoFocus, autoSelect, ref]);


    return (
        <Form.Item
            validateStatus={getValidationStatus(field, form)}
            help={getValidationMessage(field, form) || help}
            {...formItemProps}
        >
            {getChild()}
        </Form.Item>
    );
};

SMInput.propTypes = {
    label: PropTypes.node,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    required: PropTypes.bool,
    hasFeedback: PropTypes.bool,
    className: PropTypes.string,
    style: PropTypes.object,
    noStyle: PropTypes.bool,
    help: PropTypes.node,
    input: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    wrapperCol: PropTypes.object,
    labelCol: PropTypes.object,
    formItemLayout: PropTypes.object,
    children: PropTypes.func,
    colon: PropTypes.bool,
    autoFocus: PropTypes.bool,
    autoSelect: PropTypes.bool,
};

SMInput.defaultProps = {};

export default SMInput;
