import { all, call, spawn } from 'redux-saga/effects';
import { leadsSaga } from './ducks/api/leads';
import { formsSaga } from './ducks/api/forms';

const sagas = [
    leadsSaga,
    formsSaga,
];

export default function* sagaRoot() {

    yield all(sagas.map(saga =>
        spawn(function* () {
            while (true) {
                try {
                    yield call(saga);
                    break;
                } catch (e) {
                    console.error(e);
                }
            }
        }))
    );

}