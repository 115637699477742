import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import QuoteForm from './QuoteForm';
import { createLoadingSelector } from '../../store/ducks/ui/loading';
import { saveLead } from '../../store/ducks/api/leads';
import { fetchFormByUniqueId } from '../../store/ducks/api/forms';
import { App, Skeleton, Space } from 'antd';
import './Quote.less';
import { ThemeContext } from '../common/ThemeContext/ThemeContext';
import { getRecaptchaToken } from '../../services/utility.service';

const Quote = ({ saveLead, fetchFormByUniqueId, fetchFormByUniqueIdLoading, saveLeadLoading }) => {
    const { pathname, search } = window.location;
    const { message: Msg } = App.useApp();

    const isPreview = React.useMemo(() => search.indexOf('demo=1') !== -1, [search]);

    const [matchesDomain, setMatchesDomain] = React.useState(false);
    const [leadForm, setLeadForm] = React.useState({ countryCode: 'US', theme: 'light' });
    const [showSuccessMessage, setShowSuccessMessage] = React.useState(false);

    const uniqueId = React.useMemo(() => pathname.replace('/', ''), [pathname]);

    React.useEffect(() => {
        const { customCss } = leadForm;
        if (!customCss) return;

        const sheet = new CSSStyleSheet();
        sheet.replaceSync(customCss);
        document.adoptedStyleSheets = [...document.adoptedStyleSheets, sheet];

    }, [leadForm]);

    React.useEffect(() => {
        const { websites } = leadForm;
        if (!websites?.length) return;

        const referrer = document.referrer.replace(/^\/|\/$/g, '');

        const matches = websites.some((domain) => {
            const _domain = domain.replace(/^\/|\/$/g, '');
            return referrer.indexOf(_domain) === 0;
        });
        setMatchesDomain(matches);
    }, [leadForm]);

    const { setTheme } = useContext(ThemeContext);

    React.useEffect(() => {
        setTheme(leadForm.theme);
    }, [leadForm.theme, setTheme]);

    React.useEffect(() => {
        (async () => {
            const data = await fetchFormByUniqueId(uniqueId);
            setLeadForm(data);
        })();

    }, [fetchFormByUniqueId, uniqueId]);

    const notifyParent = React.useCallback((lead, error) => {
        const message = {
            lead,
            source: 'LEAD-FORM',
            success: !error,
            error: error?.message || null
        };
        const encoded = JSON.stringify(message);

        window.parent.postMessage(encoded, '*');

    }, []);

    const handleSubmit = React.useCallback(async (lead) => {
        const { redirectUri } = leadForm;

        if (isPreview) {
            notifyParent(lead);
            return setShowSuccessMessage(true);
        }

        try {
            const recaptchaToken = await getRecaptchaToken('submitLeadForm');
            const params = { ...lead, uniqueFormId: uniqueId, matchesDomain, recaptchaToken };
            await saveLead(params);
            setShowSuccessMessage(true);
            notifyParent(lead);
            if (redirectUri) window.top.location.href = redirectUri;
        } catch (error) {
            if(error?.message) Msg.error(error?.message);
            notifyParent(lead, error);
        }
    }, [saveLead, uniqueId, isPreview, matchesDomain, notifyParent, leadForm, Msg]);

    const lead = React.useMemo(() => {
        const { countryCode, customFieldsShape, defaultValues } = leadForm;
        return { countryCode, customFields: customFieldsShape, ...defaultValues };
    }, [leadForm]);

    const content = React.useMemo(() => {
        if (fetchFormByUniqueIdLoading) {
            return (
                <Space direction="vertical" size="large" className="skeleton">
                    {
                        (new Array(7)).fill(null)
                            .map((i, index) => (<Skeleton.Input key={index} active size="large" />))
                    }
                    <Skeleton.Button />
                </Space>
            );
        }

        if (showSuccessMessage) {
            return (
                <div className="thank-you-message">
                    <Space direction="vertical" size="large">
                        <div>
                            <h1>Thank you!</h1>
                            <h3>We'll get back to you shortly.</h3>
                        </div>
                    </Space>
                </div>
            );
        }

        return (
            <div className="quote-form-wrapper">
                <QuoteForm leadForm={leadForm} onSubmit={handleSubmit} loading={saveLeadLoading} lead={lead} isPreview={isPreview} />
            </div>
        );
    }, [fetchFormByUniqueIdLoading, handleSubmit, isPreview, lead, leadForm, saveLeadLoading, showSuccessMessage]);

    return content;

};

Quote.propTypes = {
    saveLead: PropTypes.func.isRequired,
    fetchFormByUniqueId: PropTypes.func.isRequired,
    saveLeadLoading: PropTypes.bool,
    fetchFormByUniqueIdLoading: PropTypes.bool,
};

const mapState = (state) => ({
    saveLeadLoading: createLoadingSelector(saveLead)(state),
    fetchFormByUniqueIdLoading: createLoadingSelector(fetchFormByUniqueId)(state),
});

export default connect(mapState, { saveLead, fetchFormByUniqueId })(Quote);