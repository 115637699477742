import React, { useContext } from 'react';
import { ConfigProvider, Layout, theme } from 'antd';
import './Root.less';
import Quote from '../Quote/Quote';
import { ThemeContext } from '../common/ThemeContext/ThemeContext';

const Root = () => {
    const { isDark } = useContext(ThemeContext);
    return (
        <ConfigProvider theme={{ algorithm: isDark ? theme.darkAlgorithm : theme.defaultAlgorithm }}>
            <Layout className="root">
                {/*<Layout.Header>*/}
                {/*    header*/}
                {/*</Layout.Header>*/}
                <Layout.Content className="content">
                    <Quote />
                </Layout.Content>
                {/*<Layout.Footer>*/}
                {/*    footer*/}
                {/*</Layout.Footer>*/}
            </Layout>
        </ConfigProvider>
    );
};

export default Root;