import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { getValidationMessage, getValidationStatus } from '../../../services/utility.service';
import { Input, Form } from 'antd';
import { connect } from 'formik';

function FormikInput(props) {

    const {
        field, label, input: CustomInput, hasFeedback, help, className, formItemLayout, wrapperCol,
        children, required, form, autoFocus, focusAfter, ...rest
    } = props;
    const inputRef = useRef();

    useEffect(() => {
        let id;
        if (focusAfter >= 0) {
            id = setTimeout(() => {
                inputRef.current.focus && inputRef.current.focus();
            }, focusAfter);
        }

        return () => id && clearTimeout(id);
    }, [focusAfter]);


    const InputControl = CustomInput || Input;
    return (
        <Form.Item
            required={required}
            label={label}
            hasFeedback={hasFeedback}
            className={className}
            validateStatus={getValidationStatus(field, form)}
            help={getValidationMessage(field, form) || help}
            wrapperCol={wrapperCol}
            {...formItemLayout}
        >
            {
                children
                ||
                <InputControl
                    ref={inputRef}
                    autoFocus={autoFocus}
                    {...field}
                    onKeyDown={field.onBlur}
                    {...rest}
                />
            }
        </Form.Item>
    );

}

FormikInput.propTypes = {
    focusAfter: PropTypes.number,
    field: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    required: PropTypes.bool,
    label: PropTypes.string,
    formItemLayout: PropTypes.object,
    wrapperCol: PropTypes.object,
    className: PropTypes.string,
    help: PropTypes.string,
    hasFeedback: PropTypes.bool,
    input: PropTypes.any,
    autoFocus: PropTypes.bool,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ])
};
FormikInput.defaultProps = {
    formItemLayout: {},
    wrapperCol: {},
    hasFeedback: true,
    autoFocus: false,
};

class ErrorFocus extends React.Component {

    componentDidUpdate(prevProps) {
        const { isSubmitting, isValidating, errors } = prevProps.formik;
        const keys = Object.keys(errors);
        if (keys.length > 0 && isSubmitting && !isValidating) {
            const nameSelector = `[name="${keys[0]}"]`;
            const idSelector = `#${keys[0]}`;
            const errorElement = document.querySelector(nameSelector) || document.querySelector(idSelector);
            if (errorElement) {
                errorElement.scrollIntoView({ block: 'center', behavior: 'smooth' });
            }
        }
    }

    render() {
        return null;
    }
}

ErrorFocus.propTypes = {
    formik: PropTypes.any,
};

const FormikControls = {
    FormikInput,
    ErrorFocus: connect(ErrorFocus)
};

export default FormikControls;