import axios from 'axios';

export const axiosApi = axios.create({
    baseURL: `/api`,
    timeout: 30 * 1000, // 30 seconds
});

const onRejected = (error) => {

    const { response, message } = error;

    let msg = message;
    const { status = 500 } = response || {};

    if (response) {
        switch (status) {
            case 401:
            case 402:
            case 403:
                msg = 'You are not authorized for this request.';
                break;
            default:
                msg = (response && response.data && response.data.message);
        }

    }

    error.message = msg;
    error.status = status;

    return Promise.reject(error);
};

axiosApi.interceptors.response.use((res) => res, onRejected);