import { get, identity } from 'lodash';
import React from 'react';
import * as Yup from 'yup';

Yup.addMethod(Yup.array, 'unique', function (message, mapper = a => a) {
    return this.test('unique', message, function (list) {
        return list.length === new Set(list.map(mapper)).size;
    });
});

export const mediaBreakpoints = {
    // Media queries breakpoints

    // Extra small screen / phone
    xs: 480,

    // Small screen / tablet
    sm: 576,

    // Medium screen / desktop
    md: 768,

    // Large screen / wide desktop
    lg: 992,

    // Extra large screen / full hd
    xl: 1200,

    // Extra extra large screen / large desktop
    xxl: 1600
};

export const toObject = (records, identifier = 'id') => {
    return records.reduce((memo, record) => {
        memo[record[identifier]] = record;
        return memo;
    }, {});
};

export const getValidationStatus = (field, form) => {
    const formSubmitted = form.submitCount > 0;
    const { validateOnMount } = form;
    if (form.isValidating) return 'validating';
    const errorMessage = get(form.errors, field.name);
    const fieldTouched = get(form.touched, field.name);
    if (errorMessage && (fieldTouched || formSubmitted || validateOnMount)) return 'error';
    if (fieldTouched) return 'success';
    return '';
};

export const getValidationMessage = (field, form) => {
    if (getValidationStatus(field, form) === 'error') {
        return get(form.errors, field.name);
    }
    return '';
};

export const yupValidate = async (value, schema) => {
    let res = { errors: null, value: null };
    try {
        res.value = await schema.validate(value);
    } catch (err) {
        res.errors = err.errors;
    }

    return res;

};

export function getRecaptchaToken(action = 'unknown') {
    return new Promise((resolve, reject) => {
        grecaptcha.ready(async () => {
            try {
                const token = await grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, { action });
                resolve(token);
            } catch (error) {
                reject(error);
            }
        });
    });
}

// Hook
let cachedScripts = [];

export function useScript(src) {
    // Keeping track of script loaded and error state
    const [state, setState] = React.useState({
        loaded: false,
        error: false
    });

    React.useEffect(
        () => {
            // If cachedScripts array already includes src that means another instance ...
            // ... of this hook already loaded this script, so no need to load again.
            if (cachedScripts.includes(src)) {
                setState({ loaded: true, error: false });
                return;
            }

            cachedScripts.push(src);

            // Create script
            let script = document.createElement('script');
            script.src = src;
            script.async = true;

            // Script event listener callbacks for load and error
            const onScriptLoad = () => {
                cachedScripts.push(src);
                setState({ loaded: true, error: false });
            };

            const onScriptError = () => {
                // Remove from cachedScripts we can try loading again
                const index = cachedScripts.indexOf(src);
                if (index >= 0) cachedScripts.splice(index, 1);
                script.remove();

                setState({ loaded: true, error: true });
            };

            script.addEventListener('load', onScriptLoad);
            script.addEventListener('error', onScriptError);

            // Add script to document body
            document.body.appendChild(script);

            // Remove event listeners on cleanup
            return () => {
                script.removeEventListener('load', onScriptLoad);
                script.removeEventListener('error', onScriptError);
            };

        },
        [src] // Only re-run effect if script src changes
    );

    return [state.loaded, state.error];
}

export const delay = (ms, result) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(result);
        }, ms);
    });
};

export const isEmail = async (value) => await Yup.string().email().isValid(value);

export const loadScript = async ({ url, id, callback = identity, scriptAttributes = {} }) => {
    const existingScript = document.getElementById(id);

    if (existingScript) {
        callback();
        return;
    }

    const script = document.createElement('script');
    script.src = url;
    script.id = id;

    Object.keys(scriptAttributes).forEach((name) => {
        script.setAttribute(name, scriptAttributes[name]);
    });

    const p = new Promise((resolve, reject) => {
        script.onload = () => {
            callback();
            resolve();
        };

        script.onerror = reject;
        window.setTimeout(reject, 5000);

    });

    document.body.appendChild(script);

    return p;

};
