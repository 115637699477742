import { chain, get } from 'lodash';
import { destroyErrors } from './error';

export default function loadingReducer(state = {}, action) {
    const { type } = action;
    const matches = /(.*)\/(TRIGGER|REQUEST|SUCCESS|FAILURE|FULFILL)/.exec(type);
    if (!matches) return state;
    if (type === destroyErrors.REQUEST) return state;

    const [, requestName, requestState] = matches;

    return {
        ...state,
        [requestName]: ['TRIGGER', 'REQUEST'].indexOf(requestState) !== -1,
    };

};

export const createLoadingSelector = (...actions) => (state) => {
    // returns true only when all actions is not loading
    return chain(actions).flatten().some((action) => {
        const prefix = action.toString().split('/')[0];
        return get(state, `ui.loading.${prefix}`);
    }).value();
};