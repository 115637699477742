import { cloneDeep, findIndex, isArray, isFunction, isNaN, isNumber, isObject, merge, values } from 'lodash';
import { handleActions } from 'redux-actions';
import { toObject } from '../services/utility.service';
import { call, put, select } from '@redux-saga/core/effects';
import { axiosApi } from '../services/axios';
import { createRoutineCreator } from 'redux-saga-routines';
import { produce, enablePatches, enableMapSet } from 'immer';
import { createSelector } from 'reselect';

enablePatches();
enableMapSet();

/* ######### Reducer creators ######### */

const defaultInitialState = {
    records: [],
    count: 0,
    selectedId: null,
    selectedRecordPreviousVersion: null,
    params: {
        offset: 0,
        limit: 10,
        search: {},
        sortField: undefined,
        sortOrder: undefined,
    }
};

const normalizeSearchParams = (params) => {
    let { search } = params;
    if (!search) search = {};

    // search = pickBy(search, (value) => !isUndefined(value));

    return {
        ...params,
        search
    };

};

const createStandardReducer = ({ types, initialState = defaultInitialState, model: Model, identifier = 'id' }) => {
    const handlers = {
        'LOGIN/SUCCESS': () => initialState,
        'LOGIN_AS/SUCCESS': () => initialState,
        'ESCAPE_AUTH/SUCCESS': () => initialState,
    };

    if (types.fetchRecords) {
        const routines = isArray(types.fetchRecords) ? types.fetchRecords : [types.fetchRecords];
        routines.forEach((routine) => {
            handlers[routine] = (state, { payload }) => produce(state, (draftState) => {
                if (isObject(payload)) {
                    draftState.params = merge(draftState.params, payload);
                    draftState.params = normalizeSearchParams(draftState.params);
                }
            });
            handlers[routine.SUCCESS] = (state, { payload, meta }) => produce(state, (draftState) => {
                let { rows } = payload;
                if (!rows) return;
                if (meta && meta.skipStateUpdate) return;
                if (Model) rows = rows.map((row) => new Model(row));
                draftState.records = rows;

                draftState.count = payload.count;
            });
        });
    }

    if (types.setParams) {
        handlers[types.setParams] = (state, action) => produce(state, (draftState) => {
            const { payload, meta } = action;

            if (!Array.isArray(payload)) {
                if (meta && meta.reset) {
                    draftState.params = merge({}, initialState.params, payload);
                } else {
                    draftState.params = merge(draftState.params, payload);
                }

                if (!draftState.params.search) draftState.params.search = {};

                // This is required so that params.search[vector] is removed when clear button is clicked in FullTextSearchField
                draftState.params.search = payload.search || {};

                // draftState.params = normalizeSearchParams(draftState.params);

                return;

            }

            const [pagination, filters, sorter] = payload;

            let params = {};
            if (pagination) {
                params.current = pagination.current;
                params.offset = pagination.pageSize * (pagination.current - 1);
                params.limit = pagination.pageSize;
            }

            if (sorter) {
                params.sortField = sorter.field;
                params.sortOrder = sorter.order;
            }

            if (filters) {
                params.search = { ...filters };
            }

            params = normalizeSearchParams(merge(draftState.params, params));

            draftState.params = params;

        });
    }

    if (types.fetchRecord) {
        const routines = isArray(types.fetchRecord) ? types.fetchRecord : [types.fetchRecord];
        routines.forEach((routine) => {
            handlers[routine.TRIGGER] = (state, { payload }) => produce(state, (draftState) => {
                const identifier = (payload && payload.identifier) || payload;
                const parsed = parseInt(identifier, 10);
                draftState.selectedId = (!isNaN(parsed) && isNumber(parsed)) ? parsed : identifier;
            });
            handlers[routine.SUCCESS] = (state, { payload }) => produce(state, (draftState) => {
                const record = Model ? new Model(payload) : payload;
                const index = findIndex(draftState.records, (r) => r[identifier] === record[identifier]);
                if (index >= 0) {
                    draftState.records[index] = record;
                } else {
                    draftState.records.push(record);
                    draftState.count = draftState.records.length;
                }
            });
        });

    }

    if (types.setRecords) {
        handlers[types.setRecords] = (state, { payload }) => produce(state, (draftState) => {

            if (Array.isArray(payload)) {
                let records = payload;
                if (Model) records = records.map((record) => record instanceof Model ? record : new Model(record));

                draftState.records = records;
                draftState.count = records.length;
            } else {
                draftState.records = values(payload).map((record) => record instanceof Model ? record : new Model(record));
                draftState.count = draftState.records.length;
            }
        });
    }

    if (types.appendRecord) {
        handlers[types.appendRecord] = (state, { payload }) => produce(state, (draftState) => {

            let record = payload;
            if (Model) {
                record = record instanceof Model ? record : new Model(record);
            }

            draftState.records.push(record);
            draftState.count = draftState.records.length;

        });
    }

    if (types.saveRecord) {
        const routines = isArray(types.saveRecord) ? types.saveRecord : [types.saveRecord];
        routines.forEach((routine) => {

            handlers[routine] = (state, { payload, meta }) => produce(state, (draftState) => {
                if (!meta.optimistic) return;
                if (!payload[identifier]) return;

                const index = findIndex(draftState.records, (r) => r[identifier] === payload[identifier]);
                if (index === -1) return;

                const existingRecord = draftState.records[index];
                draftState.selectedRecordPreviousVersion = cloneDeep(existingRecord);

                draftState.records[index] = Model ? new Model(merge(existingRecord, payload)) : merge(existingRecord, payload);

                draftState.count = draftState.records.length;
            });
            handlers[routine.SUCCESS] = (state, { payload }) => produce(state, (draftState) => {
                let record;
                const index = findIndex(draftState.records, (r) => r[identifier] === payload[identifier]);

                const existingRecord = index >= 0 ? draftState.records[index] : null;
                if (Model) {
                    record = existingRecord ? existingRecord.merge(payload) : new Model(payload);
                } else {
                    record = existingRecord ? merge(existingRecord, payload) : payload;
                }

                if (index >= 0) {
                    draftState.records[index] = record;
                } else {
                    draftState.records.push(record);
                }

                draftState.count = draftState.records.length;
                draftState.selectedRecordPreviousVersion = null;
            });
            handlers[routine.FAILURE] = (state) => produce(state, (draftState) => {
                const prevState = draftState.selectedRecordPreviousVersion;
                if (!prevState) return;
                const index = findIndex(draftState.records, (r) => r[identifier] === prevState[identifier]);
                if (index >= 0) draftState.records[index] = prevState;
                draftState.selectedRecordPreviousVersion = null;
            });

        });

    }

    if (types.destroyRecord) {
        handlers[types.destroyRecord.SUCCESS] = (state, { payload }) => produce(state, (draftState) => {
            const index = findIndex(draftState.records, (r) => r[identifier] === payload[identifier]);
            if (index !== -1) draftState.records.splice(index, 1);
            if (payload && payload[identifier] && (draftState.selectedId === payload[identifier])) {
                draftState.selectedId = null;
            }
        });
    }

    if (types.setSelectedRecordId) {
        handlers[types.setSelectedRecordId] = (state, { payload }) => produce(state, (draftState) => {
            const parsed = parseInt(payload, 10);
            draftState.selectedId = (!isNaN(parsed) && isNumber(parsed)) ? parsed : payload;
        });
    }

    return handleActions(handlers, initialState);

};

const createReducer = ({ types = {}, customReducer, initialState = defaultInitialState, model, identifier }) => {

    return function reducer(state = initialState, action) {

        if (isFunction(customReducer)) {
            const newState = customReducer(state, action);
            if (newState) return newState;
        }

        return createStandardReducer({ types, initialState, model, identifier })(state, action);

    };

};


/* ######### Selector creators ######### */

const createGetParamsSelector = (path) => (state) => state.api[path].params;
const createGetCountSelector = (path) => (state) => state.api[path].count;
const createGetRecordsSelector = (path) => (state) => state.api[path].records;
const createGetRecordsByIdSelector = (path) => createSelector(
    createGetRecordsSelector(path),
    (records) => toObject(records)
);
const createGetRecordSelector = (path, Model) => createSelector(
    createGetRecordsSelector(path), createGetSelectedIdSelector(path),
    (records, selectedId) => {
        const record = records.find((r) => r[Model.identifier] === selectedId);
        return new Model(cloneDeep(record) || {});
    }
);
const createGetSelectedIdSelector = (path) => (state) => state.api[path].selectedId;


/* ######### Routine helpers and creators ######### */

const defaultMetaCreator = {
    trigger: (payload, meta) => {
        const newMeta = { ...meta, thunk: true };
        if (!newMeta.method) newMeta.method = 'update';
        return newMeta;
    },
    request: () => {
    },
    progress: () => {
    },
    success: (payload, meta) => meta,
    failure: (payload, meta) => meta,
    fulfill: () => {
    },
};

const cancellableRoutineStages = ['TRIGGER', 'REQUEST', 'PROGRESS', 'SUCCESS', 'FAILURE', 'FULFILL', 'CANCEL'];
const createCancellableRoutine = createRoutineCreator(cancellableRoutineStages);

const RoutineCreator = function ({ model } = {}) {

    return function (typePrefix, payloadCreator, metaCreator = defaultMetaCreator) {
        const routine = createCancellableRoutine(typePrefix, payloadCreator, metaCreator);
        routine._MODEL = model;
        return routine;
    };

};

/* ######### Saga helpers and creators ######### */

function* callApi(config = {}, routine, meta) {
    try {
        const res = yield call(axiosApi, config);
        yield put(routine.success(res.data, meta));
        return res.data;
    } catch (error) {
        yield put(routine.failure(error, meta));
        throw error;
    } finally {
        yield put(routine.fulfill());
    }
}


const createFetchRecordSaga = ({ url, routine, axiosParams = {} }) => {
    return function* fetchRecord({ payload, meta }) {
        yield put(routine.request(payload));

        let identifier = payload;
        let params = {};
        if (meta.paramsSelector) params = yield select(meta.paramsSelector);

        return yield callApi(
            {
                ...axiosParams,
                url: `${url}/${identifier}`,
                method: 'get',
                params,
            },
            routine,
            meta
        );
    };

};

const createSaveRecordSaga = ({ url, routine }) => {
    return function* saveRecord({ payload, meta }) {
        if (payload && payload.id) return yield updateRecord({ payload, meta }, url, routine, meta);
        return yield createRecord({ payload, meta }, url, routine, meta);
    };
};

function* createRecord({ payload, meta }, url, routine, saveMeta) {
    yield put(routine.request(payload, meta));
    return yield callApi(
        { url, method: 'post', data: payload },
        routine,
        meta || saveMeta
    );
}

const createCreateRecordSaga = ({ url, routine }) => {
    return function* (action) {
        yield createRecord(action, url, routine);
    };
};

function* updateRecord({ payload, meta }, url, routine, saveMeta) {
    yield put(routine.request(payload, meta));

    return yield callApi(
        { url: `${url}/${payload.id}`, method: 'put', data: payload },
        routine,
        meta || saveMeta
    );
}

const createUpdateRecordSaga = ({ url, routine }) => {
    return function* (action) {
        yield updateRecord(action, url, routine);
    };
};

function getSelectedRecordIndex(draftState, throwIfNotFound = true) {
    const index = findIndex(draftState.records, (r) => r[r.identifier] === draftState.selectedId);
    if (index === -1) {
        if (throwIfNotFound) throw new Error(`Unable to find record by identifier`);
        return null;
    }
    return index;

}

function getSelectedRecord(draftState, throwIfNotFound = true) {
    const index = getSelectedRecordIndex(draftState, throwIfNotFound);
    const record = index !== null ? draftState.records[index] : null;
    if (!record) {
        if (throwIfNotFound) throw new Error(`Unable to find record by identifier`);
        return null;
    }

    return record;
}

export {
    callApi,
    produce,
    createFetchRecordSaga,
    createSaveRecordSaga,
    createCreateRecordSaga,
    createUpdateRecordSaga,
    createCancellableRoutine,
    updateRecord,
    createRecord,
    RoutineCreator,
    createReducer,
    defaultInitialState,
    createGetParamsSelector,
    createGetCountSelector,
    createGetRecordSelector,
    createGetRecordsSelector,
    createGetRecordsByIdSelector,
    createGetSelectedIdSelector,
    getSelectedRecord,
    getSelectedRecordIndex,
};