import React, { useRef } from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { getZipCodeGeolocation, Lead } from '../../store/ducks/api/leads';
import SMForm from '../common/SMForm/SMForm';
import SMInput from '../common/SMForm/SMInput';
import { Button, Col, Form, Grid, Input, Row, Select, Space, Spin, Typography } from 'antd';
import { getCountryStates } from '../../services/states';
import { connect } from 'react-redux';
import { compact } from 'lodash';
import CustomFieldsForm from './CustomFieldsForm';

Yup.addMethod(Yup.MixedSchema, 'oneOfSchemas', function (schemas) {
    return this.test(
        'one-of-schemas',
        // eslint-disable-next-line no-template-curly-in-string
        'Not all items in ${path} match one of the allowed schemas',
        (item) => schemas.some((schema) => schema.isValidSync(item, { strict: true }))
    );
});

const { useBreakpoint } = Grid;

const QuoteForm = ({ onSubmit, lead, leadForm, loading, isPreview, getZipCodeGeolocation }) => {

    const zipCodeGeolocation = useRef({});
    const screens = useBreakpoint();
    const isMobile = screens.xs === true;
    const makeAndModelInput = useRef();
    const zipInput = useRef();

    const handleZipCodeBlur = React.useCallback(async (e) => {
        const { value } = e.target;
        const isValid = value && Lead.schema.zip.isValidSync(value);
        if (!isValid) return;
        zipCodeGeolocation.current[value] = await getZipCodeGeolocation(value);
    }, [getZipCodeGeolocation]);

    const getHasVehicleError = React.useCallback((form) => {
        const hasBeenSubmitted = form.submitCount > 0;
        const fields = ['year', 'makeAndModel', 'mileage'];
        return fields.some((field) => (hasBeenSubmitted || form.touched[field]) && form.errors[field]);
    }, []);

    const getVehicleErrors = React.useCallback((form) => {
        const fields = ['year', 'makeAndModel', 'mileage'];
        return compact(fields.map((field) => form.errors[field])).join('; ');
    }, []);

    const getAddon = React.useCallback((label, className = 'required') => {
        const style = { width: '100px', display: 'inline-block' };
        return <span className={className} style={style}>{label}</span>;
    }, []);

    const getVehicleControls = React.useCallback((form) => {
        return (
            <SMInput name="modelYearMileage" addonBefore={getAddon('Vehicle')} placeholder="Model, Year, Mileage" />
        );

        if (isMobile) {
            return (
                <div className="vehicle-controls vehicle-controls-mobile">
                    <SMInput name="year">
                        {({ field, helpers }) => (
                            <Space.Compact block>

                                <span className="ant-input-group-addon">
                                    Vehicle Year
                                </span>

                                <Select
                                    value={field.value}
                                    showAction={['focus', 'click']}
                                    style={{ width: '100%' }}
                                    showArrow={false}
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.children.toString().toLowerCase().indexOf(input.toLowerCase()) === 0}
                                    onChange={helpers.setValue}
                                >
                                    {Lead.yearsEnum.map((year) => (
                                        <Select.Option value={year} key={year}>{year}</Select.Option>
                                    ))}
                                </Select>

                            </Space.Compact>
                        )}
                    </SMInput>
                    <SMInput
                        name="makeAndModel"
                        placeholder="BMW i8"
                        className="make-and-model"
                    >
                        {({ field }) => <Input ref={makeAndModelInput} addonBefore="Make &amp; Model" {...field} />}
                    </SMInput>
                    <SMInput name="mileage" addonBefore="Mileage" />
                </div>
            );
        }

        return (
            <Form.Item className={`vehicle-controls ${getHasVehicleError(form) ? 'ant-form-item-has-error' : ''}`}>
                <Row
                    className={`vehicle-row ${getHasVehicleError(form) ? 'ant-input-group-wrapper-status-error' : ''}`}>
                    <Col style={{ flex: '0 0 70px' }}>
                        <span className="ant-input-group-addon">Vehicle</span>
                        <span className="" />
                    </Col>
                    <Col style={{ flex: '1 1 auto' }}>
                        <Input.Group compact
                                     className={getHasVehicleError(form) ? 'ant-input-group-wrapper-status-error' : ''}>
                            <Select
                                name="year"
                                value={form.values.year}
                                showAction={['focus', 'click']}
                                style={isMobile ? { width: '100%' } : { flex: '0 0 100px' }}
                                showArrow={false}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children.toString().toLowerCase().indexOf(input.toLowerCase()) === 0}
                                placeholder="Year"
                                onChange={(value) => form.setFieldValue('year', value)}
                                className={getHasVehicleError(form) ? 'ant-select-status-error' : ''}
                                onSelect={() => makeAndModelInput.current.focus()}
                            >
                                {Lead.yearsEnum.map((year) => (
                                    <Select.Option value={year} key={year}>{year}</Select.Option>
                                ))}
                            </Select>
                            <Input
                                name="makeAndModel"
                                addonBefore="/"
                                placeholder="Make & Model (e.g. BMW i8)"
                                style={{ flex: '1 1 auto' }}
                                value={form.values.makeAndModel}
                                onChange={(e) => form.setFieldValue('makeAndModel', e.target.value)}
                                className={`make-and-model ${getHasVehicleError(form) ? 'ant-input-status-error' : ''}`}
                                ref={makeAndModelInput}
                            />
                            <Input
                                name="mileage"
                                addonBefore="/"
                                placeholder="Mileage"
                                style={{ flex: '0 0 140px' }}
                                value={form.values.mileage}
                                onChange={(e) => form.setFieldValue('mileage', e.target.value)}
                                className={`mileage ${getHasVehicleError(form) ? 'ant-input-number-status-error' : ''}`}
                            />
                        </Input.Group>
                    </Col>
                </Row>
                {
                    getHasVehicleError(form) &&
                    <Row>
                        <Col>
                            <div role="alert" className="ant-form-item-explain-error">
                                {getVehicleErrors(form)}
                            </div>
                        </Col>
                    </Row>
                }
            </Form.Item>
        );

    }, [getAddon, isMobile, getHasVehicleError, getVehicleErrors]);

    const colAttributes = React.useMemo(() => ({
        span: 24, sm: 24
    }), []);

    const schema = React.useMemo(() => {
        const { customFields = [], config } = leadForm;
        const { zipFieldEnabled = true, stateFieldEnabled = true } = config || {};

        const baseSchema = Lead.schema.create;
        const zipSchema = Lead.schema.zip;
        const stateSchema = Lead.schema.state;
        let schema = baseSchema;
        if (zipFieldEnabled) {
            schema = schema.concat(Yup.object().shape({ zip: zipSchema.required() }));
        }
        if (stateFieldEnabled) {
            schema = schema.concat(Yup.object().shape({ state: stateSchema.required() }));
        }

        const shape = {};
        for (const field of customFields) {
            const { uuid, isRequired, label } = field;
            let valueSchema = Yup.string().nullable().max(1024);
            if (isRequired) valueSchema = Yup.string()
                .max(1024)
                .required(`${label} is a required field`)
                .typeError(`${label} is a required field`);

            const fieldShape = Object.keys(field).reduce((memo, key) => {
                memo[key] = Yup.mixed().oneOfSchemas([
                    Yup.string().default(field[key]),
                    Yup.number().default(field[key]),
                    Yup.boolean().default(field[key]),
                    Yup.array().default(field[key]),
                ]);
                return memo;
            }, { value: valueSchema });

            shape[uuid] = Yup.object().shape(fieldShape);
        }

        const customFieldsSchema = Yup.object().shape({ customFields: Yup.object().shape(shape) });
        return schema.concat(customFieldsSchema);

    }, [leadForm]);

    const { zipFieldEnabled = true, stateFieldEnabled = true } = leadForm?.config || {};

    return (
        <Formik
            validationSchema={schema}
            onSubmit={onSubmit}
            initialValues={lead}
            enableReinitialize
            validateOnBlur={false}
            validateOnChange
        >
            {(form) => (
                <Spin spinning={loading}>

                    {/*<pre>{JSON.stringify(form, null, 2)}</pre>*/}

                    <SMForm layout="vertical" size="large">

                        <Row gutter={16}>
                            <Col {...colAttributes}>
                                <SMInput name="firstName" addonBefore={getAddon('First')} />
                            </Col>
                            <Col {...colAttributes}>
                                <SMInput name="lastName" addonBefore={getAddon('Last')} />
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col {...colAttributes}>
                                <SMInput name="email" addonBefore={getAddon('Email')} />
                            </Col>
                            <Col {...colAttributes}>
                                <SMInput name="phone" addonBefore={getAddon('Phone')} />
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            {
                                stateFieldEnabled &&
                                <Col {...colAttributes}>
                                    <div
                                        className={`${form.errors?.state ? 'ant-input-group-wrapper-status-error' : ''}`}>
                                        <SMInput name="state">
                                            {({ form, field, helpers }) => (
                                                <Space.Compact block className="ant-input-group">

                                                    <span className="ant-input-group-addon">
                                                        {getAddon(lead.countryCode === 'US' ? 'State' : 'Province')}
                                                    </span>

                                                    <Select
                                                        name="state"
                                                        autoComplete="off"
                                                        allowClear
                                                        style={{ width: '100%' }}
                                                        {...field}
                                                        onChange={helpers.setValue}
                                                        showSearch
                                                        onSelect={() => zipInput?.current?.focus?.()}
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => {
                                                            const { state } = option;
                                                            if (!state) return false;
                                                            const { name } = state;
                                                            return (name || '').toString().toLowerCase().indexOf(input.toLowerCase()) !== -1;
                                                        }}
                                                        options={
                                                            getCountryStates(form.values.countryCode).map((state) => (
                                                                { value: state.code, label: state.name, state }
                                                            ))
                                                        }
                                                    />

                                                </Space.Compact>
                                            )}
                                        </SMInput>
                                    </div>
                                </Col>
                            }
                            {
                                zipFieldEnabled &&
                                <Col {...colAttributes}>
                                    <SMInput
                                        name="zip"
                                        onBlur={handleZipCodeBlur}
                                        help={zipCodeGeolocation.current[form.values.zip] || null}
                                    >
                                        {({ field }) => <Input ref={zipInput}
                                                               addonBefore={getAddon('Zip')} {...field} />}
                                    </SMInput>
                                </Col>
                            }
                        </Row>

                        {getVehicleControls(form)}

                        <SMForm.ErrorFocus />

                        <CustomFieldsForm leadForm={leadForm} colAttributes={colAttributes} />

                        {/*<div style={{width: '300px'}}>*/}
                        {/*    <DisplayFormState form={form} attrs={['errors']} />*/}
                        {/*</div>*/}

                        {/*<Row gutter={[16, 16]}>*/}
                        {/*    <Col {...colAttributes}>*/}
                        {/*        <SMInput*/}
                        {/*            name="phoneOptIn"*/}
                        {/*            colon={false}*/}
                        {/*        >*/}
                        {/*            {({ field, helpers }) => (*/}
                        {/*                <>*/}
                        {/*                    <Checkbox*/}
                        {/*                        checked={field.value}*/}
                        {/*                        onChange={async (event) => {*/}
                        {/*                            helpers.setValue(event.target.checked === true);*/}
                        {/*                        }}*/}
                        {/*                    >*/}
                        {/*                        I agree to receive informational and conversational messages from*/}
                        {/*                        Ceramic Pro and its partners, at the phone number provided, for the*/}
                        {/*                        purpose of this quote request.*/}
                        {/*                    </Checkbox>*/}
                        {/*                    <div style={{paddingLeft: 23}}>*/}
                        {/*                        <Typography.Text type="secondary">*/}
                        {/*                            You may reply STOP at any time to opt out.*/}
                        {/*                            Message frequency varies. Msg & data rates may apply.*/}
                        {/*                            Text HELP for help.{' '}*/}
                        {/*                            <a href="https://ceramicpro.com/privacy-policy/">Privacy Policy</a>*/}
                        {/*                            {' | '}*/}
                        {/*                            <a href="https://ceramicpro.com/privacy-policy/">Mobile Terms of*/}
                        {/*                                Service</a>*/}
                        {/*                        </Typography.Text>*/}
                        {/*                    </div>*/}
                        {/*                </>*/}
                        {/*            )}*/}
                        {/*        </SMInput>*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}

                        <Row gutter={[16, 16]}>
                            <Col {...colAttributes} style={{ textAlign: 'center' }}>
                                {
                                    isPreview &&
                                    <div>
                                        <Typography.Text type="secondary">
                                            PREVIEW MODE (no real data is submitted)
                                        </Typography.Text>
                                    </div>
                                }
                            </Col>
                            <Col {...colAttributes} style={{ textAlign: 'center' }}>
                                <Button style={{ width: '300px' }} htmlType="submit" type="primary"
                                        loading={loading}>Submit</Button>
                            </Col>
                        </Row>

                    </SMForm>
                </Spin>
            )}
        </Formik>
    );
};

QuoteForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    lead: PropTypes.object,
    leadForm: PropTypes.object,
    loading: PropTypes.bool,
    isPreview: PropTypes.bool,
    getZipCodeGeolocation: PropTypes.func.isRequired,
};

export default connect(null, { getZipCodeGeolocation })(QuoteForm);