import { createRoutine } from 'redux-saga-routines';

/* ######### Actions ######### */

export const showError = createRoutine('SHOW_ERROR');
export const destroyErrors = createRoutine('DESTROY_ERRORS');

/* ######### Reducer ######### */

export default function errorReducer(state = {}, action) {
    const { type, payload } = action;
    if (type === destroyErrors.TRIGGER) return {};

    const matches = /(.*)\/(REQUEST|FAILURE)/.exec(type);

    // not a *_REQUEST / *_FAILURE actions, so we ignore them
    if (!matches) return state;

    const [
        ,
        // requestName
        ,
        requestState
    ] = matches;

    return {
        ...state,
        // Store errorMessage
        // e.g. stores errorMessage when receiving GET_TODOS_FAILURE
        //      else clear errorMessage when receiving GET_TODOS_REQUEST
        // [requestName]: requestState === 'FAILURE' ? payload.message : '',
        message: requestState === 'FAILURE' ? payload.message : '',
        status: requestState === 'FAILURE' ? payload.status : '',
    };

};

/* ######### Selectors ######### */

export const getErrorMessage = (state) => state.ui.error.message;
export const getErrorStatus = (state) => state.ui.error.status;