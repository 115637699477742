import React from 'react';
import PropTypes from 'prop-types';
import SMInput from '../common/SMForm/SMInput';
import { Col, Divider, Input, Row, Select, Space, Typography } from 'antd';
import { useFormikContext } from 'formik';

const CustomFieldsForm = ({ leadForm = {}, colAttributes = {} }) => {
    const { customFields } = leadForm;
    const form = useFormikContext();

    const getFieldLabel = React.useCallback((field) => {
        const { label, isRequired, type } = field;
        if (type === 'textArea') return `${label} ${isRequired ? '(required)' : ''}`;
        const style = { width: '100px', display: 'inline-block' };
        return <span className={isRequired ? 'required' : ''} style={style}>{label}</span>;
    }, []);

    const getFieldNode = React.useCallback((customField) => {
        const { uuid, isRequired, isActive, name, position, type, options, label, ...rest } = customField;

        let fieldNode;
        switch (type) {
            case 'text':
            default:
                fieldNode = (
                    <SMInput
                        name={`customFields.${uuid}.value`}
                        addonBefore={getFieldLabel(customField)}
                        required={isRequired}
                        {...rest}
                    />
                );
                break;

            case 'textArea':
                fieldNode = (
                    <SMInput
                        name={`customFields.${uuid}.value`}
                        {...rest}
                        input={Input.TextArea}
                        placeholder={getFieldLabel(customField)}
                        autoSize={{ minRows: 2, maxRows: 10 }}
                    />
                );
                break;

            case 'select':
                fieldNode = (
                    <div
                        className={`${form.errors?.customFields?.[uuid]?.value ? 'ant-input-group-wrapper-status-error' : ''}`}>
                        <SMInput name={`customFields.${uuid}.value`}>
                            {({ field, helpers }) => (
                                <Space.Compact block className="ant-input-group">
                                    <span className="ant-input-group-addon">{getFieldLabel(customField)}</span>
                                    <Select
                                        autoComplete="off"
                                        allowClear
                                        style={{ width: '100%' }}
                                        {...field}
                                        onChange={helpers.setValue}
                                        options={options}
                                    />
                                </Space.Compact>
                            )}
                        </SMInput>
                    </div>
                );
                break;
        }

        return (
            <Row gutter={16} key={uuid}>
                <Col {...colAttributes}>
                    {fieldNode}
                </Col>
            </Row>
        );
    }, [form, colAttributes, getFieldLabel]);

    const customFieldsNode = React.useMemo(() => {
        if (!customFields?.length) return null;
        const fields = customFields.filter((field) => !!field.isActive);
        if (!fields.length) return null;
        return fields.map(getFieldNode);
    }, [customFields, getFieldNode]);

    if (!customFieldsNode) return null;

    return (
        <>
            <Divider><Typography.Text type="secondary"><small>Additional info</small></Typography.Text></Divider>

            {customFieldsNode}

        </>
    );
};

CustomFieldsForm.propTypes = {
    leadForm: PropTypes.object,
    colAttributes: PropTypes.object,
};

export default CustomFieldsForm;